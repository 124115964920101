<template>
  <div :class="['rating', classComputed]">
    <slot />

    <div class="rating__items">
      <Icon
        v-for="(item, idx) in items"
        :key="idx"
        name="star"
        :class="[
          'rating__item',
          {
            'is-current': item?.isCurrent ? item.isCurrent : item === current,
            'is-pointer': !!values,
          },
        ]"
        @click="props.values ? onChange(item) : undefined"
      />
    </div>

    <div
      v-if="amount"
      class="rating__amount"
    >
      {{ amount }}
    </div>
  </div>

  <small
    v-if="!!values && !!errorMessage"
    :class="[
      {
        'is-error': !!props.error,
      },
      'rating__error',
    ]"
  >
    {{ errorMessage }}
  </small>
</template>

<script lang="ts" setup>
import type { IProductReviewRatingValue } from '@types';
import { Icon } from '@shared/Icon';

interface IProps {
  modelValue?: string;
  values?: IProductReviewRatingValue[];
  current?: string | number;
  amount?: number;
  size?: 'lg';
  error?: string | boolean | null;
}

const props = defineProps<IProps>();

const $emit = defineEmits(['update:modelValue']);

const items = computed(() => {
  if (props?.values) {
    return props?.values.map((item) => {
      if (item.valueId === props?.modelValue) {
        item.isCurrent = true;
      } else {
        item.isCurrent = false;
      }

      return item;
    });
  } else {
    return [...(Array.from({ length: 5 }, (_, e) => e + 1) as any)];
  }
});

const errorMessage = computed(() => {
  return props.error && typeof props.error === 'string' ? props.error : null;
});

const classComputed = computed(() => {
  return [
    {
      'is-checkbox': !!props.values,
    },
    props.size && `rating--${props.size}`,
  ];
});

function onChange(item: IProductReviewRatingValue) {
  props?.values?.forEach((item) => {
    item.isCurrent = false;
  });

  item.isCurrent = true;

  $emit('update:modelValue', item.valueId);
}
</script>

<style lang="scss" src="./Rating.scss" />
